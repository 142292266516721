import {Component, Input} from '@angular/core';
import {WarningColorType} from '@nit-core/global/domain/enums';
import {Warning} from '@nit-core/models/warning';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'nit-tech-alert-message',
  templateUrl: './tech-alert-message.component.html',
  styleUrl: './tech-alert-message.component.scss',
  standalone: true,
  imports: [CommonModule]
})
export class TechAlertMessageComponent {
  @Input() warning: Warning;
  warningColorType = WarningColorType;

}
